import {useMemo} from 'react';
import {map} from 'lodash';
import {CATEGORIES_TYPES} from '../utils/constants';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import LinkIcon from '@mui/icons-material/Link';

export default function useCategoryTypes() {
  const typesOptions = useMemo(
    () => map(CATEGORIES_TYPES, (type, key) => ({ id: key, name: type })),
    []
  );

  const typesIcons = useMemo(() => {
    return {
      [CATEGORIES_TYPES.GROUP_ONLY]: <AccountTreeOutlinedIcon/>,
      [CATEGORIES_TYPES.ITEM_ONLY]: <LocalOfferOutlinedIcon/>,
      [CATEGORIES_TYPES.MIXED]: <SwapHorizOutlinedIcon/>,
      [CATEGORIES_TYPES.URL]: <LinkIcon/>
    }
  }, [])


  return {typesOptions, typesIcons}
}