export const CATEGORIES_TYPES = {
  MIXED: 'mixed',
  GROUP_ONLY: 'group_only',
  ITEM_ONLY: 'item_only',
  URL: 'URL',
};

export const CATEGORIES_DISPLAY_TYPES = {
  SHOW_CATEGORY: 'show_category',
  HIDE_CATEGORY_SHOW_CHILDREN: 'hide_category_show_children',
  HIDE_CATEGORY_AND_CHILDREN: 'hide_category_and_children',
};

export const MAP_APP_MODEL_BY_RESOURCE = {
  'dProductsGroup': {
    appName: 'main',
    modelName: 'ProductsGroup'
  },

  'dBlogArticle': {
    appName: 'main',
    modelName: 'BlogArticle'
  },

  'dConfig': {
    appName: 'main',
    modelName: 'Config'
  },

  'dFeatureType': {
    appName: 'product_page',
    modelName: 'FeatureType'
  },

  'dManager': {
    appName: 'main',
    modelName: 'Manager'
  },

  'dMenuItems': {
    appName: 'main',
    modelName: 'MenuItems'
  },

  'dPhoneMessenger': {
    appName: 'main',
    modelName: 'PhoneMessenger'
  },

  'dNews': {
    appName: 'main',
    modelName: 'News'
  },

  'dPromotion': {
    appName: 'promotion',
    modelName: 'Promotion'
  },

  'dPromotionBadge': {
    appName: 'promotion',
    modelName: 'PromotionBadge'
  },

  'dSizeChart': {
    appName: 'product_page',
    modelName: 'SizeChart'
  },
}